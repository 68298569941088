import { request, requestPostFormData } from './axios'
import { BASE_URL } from './axios'
import { getHeaders } from '../utils/aiAuth'
import { TopicId, Node, DEVICE_ID, DEVICE_SECRET, CHANNEL_ID } from '.././config/ai'

/**
 * @description -封装User类型的接口方法
 */
export class UserService {       // 模块一
    /**
     * @description 用户登录
     * @param {string} username - 用户名
     * @return {HttpResponse} result
     */
    static async login1(params) {   // 接口一
        return request(BASE_URL.business, '/login', params, 'post')
    }
    static async login2(params) {   // 接口二
        return request(BASE_URL.business, '/login', params, 'post')
    }
    static async login3(params) {   // 接口三
        return request(BASE_URL.business, '/login', params, 'post')
    }
}

export class landRelevant {     // 模块二
    /**
     * @description 获取地列表
     * @return {HttpResponse} result
     */
    static async landList(params) {
        return request(BASE_URL.business, '/land_list_info', params, 'get')
    }
}

// 订单模块
export class order {
    /**
     * @description 查询订单状态
     * @return {HttpResponse} result
     * mobile=xxxxxxxx&productId=xxxxxxxxxxxxxxx
     */
    static async getOrderStatus(params) {
        return request(BASE_URL.business, '/ringtones/interface/ring/imspSubOuery.jsp', params, 'get')
    }

    /**
     * @description 更换视频铃声
     * @return {HttpResponse} result
     * phoneNumber=xxxxxxxx&toneCode=xxxxxxxxxxxxxxx
     */
    static async changeVideoRing(params) {
        return request(BASE_URL.business, '/ringtones/interface/ring/orderFee.jsp', params, 'get')
    }

    /**
     * @description 订购
     * @return {HttpResponse} result
     * mobile=xxxxxxxx&productId=xxxxxxxxxxxxxxx&returnUrl=http://xxx.xxx.xxx.xxx&channelId=xxxxxx&cpparam=xxxxxxx
     */
    static async submitOrder(params) {
        // return request('/ringtones/interface/ring/orderSub.jsp', params, 'get')
        return request(BASE_URL.business, '/ringtones/interface/ring/orderSubTest.jsp', params, 'get')
    }
}

// 视频模板合成
// http://47.104.109.210/ringtones/interface/ring/ai/diyMake.jsp
// DIY 视频彩铃入库
// http://47.104.109.210/ringtones/interface/ring/ai/applyDiy.jsp
// 获取 CMS AI 模板链接地址
// http://47.104.109.210/ringtones/interface/ring/ai/template.jsp

export class ai {


    // 获取ai模板列表
    static async getAiTemplateList(params) {
        // https://api.118100.cn/openapi/services/v3/cms/vue_client/query_temp
        // late_video.json
        params = {
            "topicId": TopicId,
            "node": Node
        }
        console.log(params);
        return request(BASE_URL.backend_ai, '/ringtones/interface/ring/ai/cmsQuery.jsp', params, 'GET')
        // return requestPostFormData(BASE_URL.business_ai, '/v3/cms/vue_client/query_template_video.json', params, getHeaders(params))
    }


    // 获取 CMS AI 模板链接地址
    static async getAiTemplateUrl(params) {
        params = {
            "return_url": TopicId,
        }
        return request(BASE_URL.business_ai, '/v3/cms/hapi/get_ai_template_url.json', params, 'POST')
    }


    // 视频上传
    static async uploadFile(params) {
        // return request(BASE_URL.business_ai, '/v3/diyvrbtservice/upload/uploadaivideofiles.json', params, 'POST', getHeaders({}))
        return request(BASE_URL.backend_ai, '/ringtones/interface/ring/ai/update_file.jsp', params, 'POST', getHeaders({}))
    }

    // 根据模板合成视频彩铃
    static async makeRing(params) {
        // return request(BASE_URL.business_ai, '/v3/diyvrbtservice/diymake/commitbytemplate.json', params, 'POST')
        return request(BASE_URL.backend_ai, '/ringtones/interface/ring/ai/diyMake.jsp', params, 'GET')
    }

    // 查询视频合成结果
    // params = {
    //     "taskid": "" // 模板合成视频彩铃接口返回的taskid(必填)
    // }
    static async getMakeRingResult(params) {
        // return request(BASE_URL.business_ai, '/v3/diyvrbtservice/diymake/commitbytemplate.json', params, 'POST')
        return request(BASE_URL.backend_ai, '/ringtones/interface/ring/ai/callbackQuery.jsp', params, 'GET')
    }


    // DIY视频彩铃入库
    static async aiVideoSave(params) {
        // return request(BASE_URL.business_ai, '/v3/diyvrbtservice/diy/applydiy.json', params, 'POST')
        return request(BASE_URL.backend_ai, '/ringtones/interface/ring/ai/applyDiy.jsp', params, 'GET')
    }

    // 获取 DIY视频彩铃入库 结果
    static async getAiVideoSaveResult(params) {
        // return request(BASE_URL.business_ai, '/v3/diyvrbtservice/diy/applydiy.json', params, 'POST')
        return request(BASE_URL.backend_ai, '/ringtones/interface/ring/ai/callback_diyQuery.jsp', params, 'GET')
    }
}


