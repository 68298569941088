import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useVideoListStore = defineStore('videoList', () => {
    const videoList = ref([
        { "imgUrl": "/img/ring/嘴馋的猴子.jpg", "ringCode": "910100325579", "title": "嘴馋的猴子" },
        { "imgUrl": "/img/ring/忠实的大象.jpg", "ringCode": "910100325527", "title": "忠实的大象" },
        { "imgUrl": "/img/ring/忠厚的动物们.jpg", "ringCode": "910100325576", "title": "忠厚的动物们" },
        { "imgUrl": "/img/ring/正直的小动物们.jpg", "ringCode": "910100325578", "title": "正直的小动物们" },
        { "imgUrl": "/img/ring/展翅翱翔的鸟儿.jpg", "ringCode": "910100325528", "title": "展翅翱翔的鸟儿" },
        { "imgUrl": "/img/ring/粘人的小猴子.jpg", "ringCode": "910100325577", "title": "粘人的小猴子" },
        { "imgUrl": "/img/ring/幼稚的猴子.jpg", "ringCode": "910100325529", "title": "幼稚的猴子" },
        { "imgUrl": "/img/ring/悠然自得的鸟儿.jpg", "ringCode": "910100325575", "title": "悠然自得的鸟儿" },
        { "imgUrl": "/img/ring/用功的知了.jpg", "ringCode": "910100325573", "title": "用功的知了" },
        { "imgUrl": "/img/ring/慵懒的海豹.jpg", "ringCode": "910100325526", "title": "慵懒的海豹" },
        { "imgUrl": "/img/ring/炎热的栖息地.jpg", "ringCode": "910100325572", "title": "炎热的栖息地" },
        { "imgUrl": "/img/ring/雄壮的豹子.jpg", "ringCode": "910100325525", "title": "雄壮的豹子" },
        { "imgUrl": "/img/ring/凶悍的动物.jpg", "ringCode": "910100325574", "title": "凶悍的动物" },
        { "imgUrl": "/img/ring/辛勤的知了.jpg", "ringCode": "910100325524", "title": "辛勤的知了" },
        { "imgUrl": "/img/ring/小心翼翼的鸟妈妈.jpg", "ringCode": "910100325571", "title": "小心翼翼的鸟妈妈" },
        { "imgUrl": "/img/ring/细心呵护的鸟蛋.jpg", "ringCode": "910100325570", "title": "细心呵护的鸟蛋" },
        { "imgUrl": "/img/ring/吸引雌性的雄孔雀.jpg", "ringCode": "910100325569", "title": "吸引雌性的雄孔雀" },
        { "imgUrl": "/img/ring/务实的小螃蟹.jpg", "ringCode": "910100325568", "title": "务实的小螃蟹" },
        { "imgUrl": "/img/ring/稳重的猴子.jpg", "ringCode": "910100325567", "title": "稳重的猴子" },
        { "imgUrl": "/img/ring/微不足道的蚂蚁.jpg", "ringCode": "910100325523", "title": "微不足道的蚂蚁" },
        { "imgUrl": "/img/ring/威猛的老虎.jpg", "ringCode": "910100325566", "title": "威猛的老虎" },
        { "imgUrl": "/img/ring/讨人喜爱的松鼠.jpg", "ringCode": "910100325565", "title": "讨人喜爱的松鼠" },
        { "imgUrl": "/img/ring/淘气的小鸟.jpg", "ringCode": "910100325564", "title": "淘气的小鸟" },
        { "imgUrl": "/img/ring/贪婪的豹子.jpg", "ringCode": "910100325520", "title": "贪婪的豹子" },
        { "imgUrl": "/img/ring/随和的猴子们.jpg", "ringCode": "910100325563", "title": "随和的猴子们" },
        { "imgUrl": "/img/ring/四肢轻快的松鼠.jpg", "ringCode": "910100325561", "title": "四肢轻快的松鼠" },
        { "imgUrl": "/img/ring/数不胜数的鸟儿.jpg", "ringCode": "910100325562", "title": "数不胜数的鸟儿" },
        { "imgUrl": "/img/ring/树林里震怒的鹿.jpg", "ringCode": "910100325522", "title": "树林里震怒的鹿" },
        { "imgUrl": "/img/ring/树林里娇俏的鹿.jpg", "ringCode": "910100325521", "title": "树林里娇俏的鹿" },
        { "imgUrl": "/img/ring/森林中的百兽之王.jpg", "ringCode": "910100325560", "title": "森林中的百兽之王" },
        { "imgUrl": "/img/ring/森林里肥壮的鹿.jpg", "ringCode": "910100325556", "title": "森林里肥壮的鹿" },
        { "imgUrl": "/img/ring/撒娇的母老虎.jpg", "ringCode": "910100325519", "title": "撒娇的母老虎" },
        { "imgUrl": "/img/ring/洒脱的大象.jpg", "ringCode": "910100325559", "title": "洒脱的大象" },
        { "imgUrl": "/img/ring/弱小的蚂蚁.jpg", "ringCode": "910100325518", "title": "弱小的蚂蚁" },
        // { "imgUrl": "/img/ring/惹人喜爱的小鸟.jpg", "ringCode": "910100325558", "title": "惹人喜爱的小鸟" },
        { "imgUrl": "/img/ring/勤快的鸟儿.jpg", "ringCode": "910100325516", "title": "勤快的鸟儿" },
        { "imgUrl": "/img/ring/勤苦的动物们.jpg", "ringCode": "910100325517", "title": "勤苦的动物们" },
        { "imgUrl": "/img/ring/勤恳的小动物.jpg", "ringCode": "910100325514", "title": "勤恳的小动物" },
        { "imgUrl": "/img/ring/勤奋的昆虫们.jpg", "ringCode": "910100325557", "title": "勤奋的昆虫们" },
        { "imgUrl": "/img/ring/气鼓鼓的蛤蟆.jpg", "ringCode": "910100325554", "title": "气鼓鼓的蛤蟆" },
        { "imgUrl": "/img/ring/胖乎乎的企鹅.jpg", "ringCode": "910100325555", "title": "胖乎乎的企鹅" },
        { "imgUrl": "/img/ring/盘旋而上的鸟儿.jpg", "ringCode": "910100325513", "title": "盘旋而上的鸟儿" },
        { "imgUrl": "/img/ring/努力的鸟儿.jpg", "ringCode": "910100325553", "title": "努力的鸟儿" },
        { "imgUrl": "/img/ring/鸟儿们瑟瑟齐鸣.jpg", "ringCode": "910100325551", "title": "鸟儿们瑟瑟齐鸣" },
        { "imgUrl": "/img/ring/美味的野果.jpg", "ringCode": "910100325550", "title": "美味的野果" },
        { "imgUrl": "/img/ring/忙碌的海鸟.jpg", "ringCode": "910100325552", "title": "忙碌的海鸟" },
        { "imgUrl": "/img/ring/漫天飞舞的鸟儿.jpg", "ringCode": "910100325547", "title": "漫天飞舞的鸟儿" },
        { "imgUrl": "/img/ring/漫山遍野的鸟儿.jpg", "ringCode": "910100325542", "title": "漫山遍野的鸟儿" },
        { "imgUrl": "/img/ring/灵巧的猴子.jpg", "ringCode": "910100325544", "title": "灵巧的猴子" },
        { "imgUrl": "/img/ring/灵动的鸟儿.jpg", "ringCode": "910100325540", "title": "灵动的鸟儿" },
        { "imgUrl": "/img/ring/林子里贪嘴的羊.jpg", "ringCode": "910100325548", "title": "林子里贪嘴的羊" },
        { "imgUrl": "/img/ring/林子里懵懂的鹿.jpg", "ringCode": "910100325515", "title": "林子里懵懂的鹿" },
        { "imgUrl": "/img/ring/快乐的动物们.jpg", "ringCode": "910100325549", "title": "快乐的动物们" },
        { "imgUrl": "/img/ring/开朗的猴子.jpg", "ringCode": "910100325541", "title": "开朗的猴子" },
        { "imgUrl": "/img/ring/矫捷的豹子.jpg", "ringCode": "910100325545", "title": "矫捷的豹子" },
        { "imgUrl": "/img/ring/娇小玲珑的小鸟.jpg", "ringCode": "910100325546", "title": "娇小玲珑的小鸟" },
        { "imgUrl": "/img/ring/健壮的豹子.jpg", "ringCode": "910100325543", "title": "健壮的豹子" },
        { "imgUrl": "/img/ring/银河横空的银河.jpg", "ringCode": "910100325536", "title": "银河横空的银河" },
        { "imgUrl": "/img/ring/耀眼星光的银河.jpg", "ringCode": "910100325532", "title": "耀眼星光的银河" },
        { "imgUrl": "/img/ring/遥远的银河.jpg", "ringCode": "910100325512", "title": "遥远的银河" },
        { "imgUrl": "/img/ring/绚丽的星光.jpg", "ringCode": "910100325537", "title": "绚丽的星光" },
        { "imgUrl": "/img/ring/星空万里的银河.jpg", "ringCode": "910100325539", "title": "星空万里的银河" },
        { "imgUrl": "/img/ring/星光四射的银河.jpg", "ringCode": "910100325511", "title": "星光四射的银河" },
        { "imgUrl": "/img/ring/星光闪耀的银河.jpg", "ringCode": "910100325533", "title": "星光闪耀的银河" },
        { "imgUrl": "/img/ring/星斗满天的银河.jpg", "ringCode": "910100325538", "title": "星斗满天的银河" },
        { "imgUrl": "/img/ring/无数繁星的银河.jpg", "ringCode": "910100325535", "title": "无数繁星的银河" },
        { "imgUrl": "/img/ring/伟大的地球母亲.jpg", "ringCode": "910100325534", "title": "伟大的地球母亲" },
        { "imgUrl": "/img/ring/唯美的星光.jpg", "ringCode": "910100325531", "title": "唯美的星光" },
        { "imgUrl": "/img/ring/万点繁星的银河.jpg", "ringCode": "910100325510", "title": "万点繁星的银河" },
        { "imgUrl": "/img/ring/壮观的瀑布.jpg", "ringCode": "910100325509", "title": "壮观的瀑布" },
        { "imgUrl": "/img/ring/庄严的雪山.jpg", "ringCode": "910100325508", "title": "庄严的雪山" },
        { "imgUrl": "/img/ring/庄严的山脉.jpg", "ringCode": "910100325507", "title": "庄严的山脉" },
        { "imgUrl": "/img/ring/云雾缭绕的山脉.jpg", "ringCode": "910100325506", "title": "云雾缭绕的山脉" },
        // { "imgUrl": "/img/ring/云雾缭绕的山峰.jpg", "ringCode": "910100325505", "title": "云雾缭绕的山峰" },
        { "imgUrl": "/img/ring/云山雾绕.jpg", "ringCode": "910100325504", "title": "云山雾绕" },
        { "imgUrl": "/img/ring/银装素裹的雪山.jpg", "ringCode": "910100325530", "title": "银装素裹的雪山" },
        { "imgUrl": "/img/ring/依山旁水.jpg", "ringCode": "910100325503", "title": "依山旁水" },
        { "imgUrl": "/img/ring/一望无际的山脉.jpg", "ringCode": "910100325502", "title": "一望无际的山脉" },
        { "imgUrl": "/img/ring/一望无际的平原.jpg", "ringCode": "910100325498", "title": "一望无际的平原" },
        { "imgUrl": "/img/ring/郁郁苍苍的树林.jpg", "ringCode": "910100325501", "title": "郁郁苍苍的树林" },
        { "imgUrl": "/img/ring/幽静的树林.jpg", "ringCode": "910100325497", "title": "幽静的树林" },
        { "imgUrl": "/img/ring/稀疏的森林.jpg", "ringCode": "910100325500", "title": "稀疏的森林" },
        { "imgUrl": "/img/ring/万物复苏的植物.jpg", "ringCode": "910100325499", "title": "万物复苏的植物" },
        { "imgUrl": "/img/ring/湍急的河水.jpg", "ringCode": "910100325496", "title": "湍急的河水" },
        { "imgUrl": "/img/ring/夜色朦胧的城市.jpg", "ringCode": "910100325495", "title": "夜色朦胧的城市" },
        { "imgUrl": "/img/ring/夜色阑珊的城市.jpg", "ringCode": "910100325494", "title": "夜色阑珊的城市" },
        { "imgUrl": "/img/ring/夜幕中的城市.jpg", "ringCode": "910100325492", "title": "夜幕中的城市" },
        { "imgUrl": "/img/ring/夜幕下的城市.jpg", "ringCode": "910100325491", "title": "夜幕下的城市" },
        { "imgUrl": "/img/ring/雪中的城市.jpg", "ringCode": "910100325493", "title": "雪中的城市" },
        { "imgUrl": "/img/ring/秀丽的大海.jpg", "ringCode": "910100325484", "title": "秀丽的大海" },
        { "imgUrl": "/img/ring/美妙的大自然.jpg", "ringCode": "910100325486", "title": "美妙的大自然" },
        { "imgUrl": "/img/ring/美丽的海滩.jpg", "ringCode": "910100325482", "title": "美丽的海滩" },
        { "imgUrl": "/img/ring/美丽的大海.jpg", "ringCode": "910100325488", "title": "美丽的大海" },
        { "imgUrl": "/img/ring/美丽安静的沙滩.jpg", "ringCode": "910100325490", "title": "美丽安静的沙滩" },
        { "imgUrl": "/img/ring/美不胜收的大海.jpg", "ringCode": "910100325480", "title": "美不胜收的大海" },
        { "imgUrl": "/img/ring/辽阔的大海.jpg", "ringCode": "910100325487", "title": "辽阔的大海" },
        { "imgUrl": "/img/ring/雷电交加.jpg", "ringCode": "910100325485", "title": "雷电交加" },
        { "imgUrl": "/img/ring/浪漫的夕阳.jpg", "ringCode": "910100325481", "title": "浪漫的夕阳" },
        { "imgUrl": "/img/ring/蓝色的大海.jpg", "ringCode": "910100325489", "title": "蓝色的大海" },
        { "imgUrl": "/img/ring/狂涛怒吼的大海.jpg", "ringCode": "910100325483", "title": "狂涛怒吼的大海" },
    ]);

    return {
        videoList,
    }
})