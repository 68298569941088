import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePhoneNumberStore = defineStore('phoneNumber', () => {
    // 落地页订购输入框手机号
    const phoneNumber = ref('')
    // 登录页手机号
    const loginPhoneNumber = ref('')
    const setPhoneNumber = (value) => {
        phoneNumber.value = value
    } 

    const setLoginPhoneNumber = (value) => {
        loginPhoneNumber.value = value
    }

    const isAgreement = ref(false)

    const setIsAgreement = (value) => {
        isAgreement.value = value
    }

    return {
        phoneNumber,
        setPhoneNumber,
        loginPhoneNumber,
        setLoginPhoneNumber,
        isAgreement,
        setIsAgreement,
    }
})