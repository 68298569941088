<script setup>
import { useRoute } from 'vue-router';
import { useVideoListStore } from '/src/stores/videoList.js';
import { ElMessage, linkEmits } from 'element-plus'
import { usePhoneNumberStore } from '/src/stores/phone.js';
import { order } from '/src/api/api.js';
import { Snackbar } from '@varlet/ui'
import { ref } from 'vue';
import { ProductId } from '/src/config/order.js'
import { ChannelId } from '/src/config/order.js'
import { ReturnUrl } from '/src/config/order.js'
import LoginWindow from '/src/components/Login/LoginWindow.vue'


// 从url中获取视频id
const videoId = useRoute().params.id;
const videoList = useVideoListStore().videoList;
// 检查是否存在
if (typeof videoList[videoId] === "undefined") {
    // 视频不存在
    ElMessage('视频不存在')
    console.error('视频不存在');
    window.history.back();
}
const video = videoList[videoId];
const cover = 'cover';
console.log(video.title);

function back() {
    window.history.back();
}

const phoneNumberStore = usePhoneNumberStore();
let loginPhoneNumber = ref("")
let loginWindowShow = ref(false)
// 登录后事件
function loginWindowShowClose() {
    console.log('1111111111111111111')
    loginWindowShow.value = false
    // 判断是否登录成功
    loginPhoneNumber.value = phoneNumberStore.loginPhoneNumber
    
    if (loginPhoneNumber.value && loginPhoneNumber.value.length === 11) {
        // 登录成功, 调用设置接口, 设置铃声信息
        changeVideo(video.ringCode)
    }
}

// 选择视频
async function changeVideo(ringCode) {
    if (loginPhoneNumber.value.length === 0) {
        Snackbar['error']("请输入手机号码")
        return
    }
    if (!/^1[3-9]\d{9}$/.test(loginPhoneNumber.value)) {
        Snackbar['error']("请输入正确的手机号码")
        return
    }

    // 调用接口
    order.changeVideoRing({ phoneNumber: loginPhoneNumber.value, toneCode: ringCode }).then(res => {
        console.log("设置视频" + JSON.stringify(res));

        if (res.status === 200) {
            if (res.data.res_code == "0") {
                // 已订购, 登录成功
                Snackbar['success']("设置成功")
                return
            } else {
                // 已订购, 登录成功
                // Snackbar['error']("设置失败")
                // return
                // 未订购, 弹窗提示
                // Snackbar['success']("您尚未订购该服务")
                notBuy('您尚未订购该服务, 请先订购后登录')
                return
            }
        } else {
            Snackbar['error']("系统繁忙，请稍后再试")
            return
        }

    }).catch(err => {
        console.log(err)
    })
}

/**
 * @description 未订购提示后跳转到首页
 * @param {*} message 提示信息
 */
async function notBuy(message) {
    notBuyActions[await Dialog({
        message: message,
        confirmButton: false,
        cancelButton: false,
    })]()
}
const notBuyActions = {
    // confirm: () => Snackbar.success('confirm'),
    // cancel: () => Snackbar.error('cancel'),
    close: () => router.push({ name: 'home' })
}
</script>
<template>
    <div class="container">
        <div class="title">
            <img @click="back()" class="closelogo"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEKADAAQAAAABAAAAEAAAAAA0VXHyAAAAqElEQVQ4Ea1TQQ7CMAyLdoD/P2Nw3OABwL+YPdWSqdYQISpZXVPbSdc0IuIJXIEzUB3kXoAXBfx4A3egYnICb20aasMDN6wzkyF3uMEMbXzlZATuLQCPmlbpJjwn12UxuPvoTUqZJdbsJmnZkxT/nD07e0N3nv48FdCL2RMeS02c2Hel7x2aOKEXqzpWc3icithNWMHHzcwtMMossWZWIpP9MT0Q+Pk5b/kuQ+lK3MFWAAAAAElFTkSuQmCC">
            <span>预览效果</span>
        </div>
        <el-image class="image" :src="video.imgUrl" :fit="cover" />
        <img class="order" src="/img/web/set-2.png" alt="" @click="loginWindowShow = true" />
    </div>
    <!-- 登录窗口 -->
    <LoginWindow v-if="loginWindowShow" @close="loginWindowShowClose" />
</template>
<style scoped lang="less">
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #333;

    .title {
        width: 100%;
        height: 15vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .closelogo {
            left: 5%;
            position: absolute;
        }
    }


    .image {
        width: 80%;
    }


    .order {
        width: 40%;
        margin-top: 20px;
    }
}
</style>