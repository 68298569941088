<script setup>
import { ref, defineEmits } from 'vue';
import { order } from '/src/api/api.js';
import { usePhoneNumberStore } from '/src/stores/phone.js';
import { Dialog } from '@varlet/ui'
import { Snackbar } from '@varlet/ui'
import router from '/src/router/router.js';
import { ProductId } from '/src/config/order.js'



/**
 * @description 定义事件
 */
const emit = defineEmits(['close']);
/**
 * @description 关闭登录界面
 */
function emitClose() {
    emit('close');
}
// function closeLogin() {
//     emitClose();
// }


const phoneNumber = ref('');
const phoneNumberStore = usePhoneNumberStore();

/**
 * @description 登录
 */
async function login() {
    if (phoneNumber.value.length === 0) {
        Snackbar['error']("请输入手机号码")
        return
    }
    if (!/^1[3-9]\d{9}$/.test(phoneNumber.value)) {
        Snackbar['error']("请输入正确的手机号码")
        return
    }

    // 调用接口
    order.getOrderStatus({ mobile: phoneNumber.value, productId: ProductId }).then(res => {
        // phoneNumberStore.setLoginPhoneNumber(phoneNumber.value);
        // emitClose(); 
        // return
        console.log(res);
        if (res.status === 200) {
            if (res.data.res_code == "-1") {
                // 已订购, 登录成功
                Snackbar['success']("登录成功")
                phoneNumberStore.setLoginPhoneNumber(phoneNumber.value);
                emitClose();
                return
            } else {
                // 未订购, 弹窗提示
                // Snackbar['error']("您尚未订购该服务")
                notBuy('您尚未订购该服务, 请先订购后登录')
                return
            }
        } else {
            Snackbar['error']("系统繁忙，请稍后再试")
            return
        }

    }).catch(err => {
        console.log(err)
    })
}

/**
 * @description 未订购提示后跳转到首页
 * @param {*} message 提示信息
 */
async function notBuy(message) {
    notBuyActions[await Dialog({
        message: message,
        confirmButton: false,
        cancelButton: false,
    })]()
}
const notBuyActions = {
    // confirm: () => Snackbar.success('confirm'),
    // cancel: () => Snackbar.error('cancel'),
    close: () => router.push({ name: 'home' })
}



// 隐藏按钮
// function hideButton(message) {
//     Dialog({
//         message: message,
//         confirmButton: false,
//         cancelButton: false,
//     })
// }

const show = ref(true);
</script>

<template>
    <!-- 创建一个遮罩 -->
    <var-overlay v-model:show="show" @click="emitClose">
        <!-- 显示提示信息 -->
        <div class="contents" @click.stop>
            <svg style="position: absolute; top: 10px; right: 10px; cursor: pointer;" @click="emitClose"
                t="1712682760848" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="991" width="22px" height="22px">
                <path
                    d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333zM373.290667 343.594667a21.333333 21.333333 0 0 0-27.221334 32.64l135.765334 135.68-135.765334 135.850666-2.474666 2.986667a21.333333 21.333333 0 0 0 32.64 27.178667L512 542.122667l135.765333 135.808 2.986667 2.474666a21.333333 21.333333 0 0 0 27.178667-32.64l-135.765334-135.808 135.765334-135.722666 2.474666-2.986667a21.333333 21.333333 0 0 0-32.64-27.178667L512 481.706667l-135.765333-135.68z"
                    p-id="992" fill="#d81e06"></path>
            </svg>
            <span class="login-title">登录</span>
            <var-input variant="outlined" placeholder="请输入手机号码" v-model="phoneNumber" minlength="11" maxlength="11" />
            <!-- <el-input class="phone-input" placeholder="请输入手机号码" v-model="phoneNumber" maxlength="11" /> -->
            <!-- <div class="btn-box">确定</div> -->
            <var-button type="primary" @click="login()">确定</var-button>
        </div>
    </var-overlay>
</template>

<style scoped>

.contents {
    max-width: 1000px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 80vw;
    /* height: 400px; */
    padding: 5vw;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-radius: 3vw;


    .login-title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
    }
}
</style>