<script setup>
import { ref, watch, computed } from 'vue'
import { usePhoneNumberStore } from '/src/stores/phone.js'
import { useVideoListStore } from '/src/stores/videoList.js'
import SelectVideoListOne from '/src/components/Video/SelectVideoListOne.vue'
import LoginWindow from '/src/components/Login/LoginWindow.vue'
import { ProductId } from '/src/config/order.js'
import { order } from '/src/api/api.js';
import { Utils } from '/src/utils/utils.js';
import { ai } from '/src/api/api.js'
import VideoListOne from '/src/components/Video/VideoListOne.vue'
import { useRouter } from 'vue-router'
import { Snackbar } from '@varlet/ui'

// 获取路由实例
const router = useRouter();

// 加载视频列表
const videoList = useVideoListStore().videoList
// 初始化展示在页面的视频列表
const showVideoList = ref([])
const videoNum = ref(0)
// 滚动加载视频列表
let isStopScroll = ref(false)
const load = () => {
    for (let i = 0; i < 4; i++) {
        const tempVideo = ref([])
        for (let j = 0; j < 2; j++) {

            if (videoList[videoNum.value] === undefined) {
                return
            }
            tempVideo.value.push(videoList[videoNum.value])
            videoNum.value += 1
            if (videoNum.value >= videoList.length - 1) {
                isStopScroll = true
            }
        }
        showVideoList.value.push(tempVideo.value)
    }
}

// 初始化登录手机号
const phoneNumberStore = usePhoneNumberStore()
const loginPhoneNumber = computed(() => phoneNumberStore.loginPhoneNumber);
const showLoginPhoneNumber = ref('')
// phoneNumberStore.setLoginPhoneNumber('13333333333');
// console.log(loginPhoneNumber.value.length);
// 如果登录手机号长度为11位，则显示中间四位为****
if (loginPhoneNumber.value.length === 11) {
    showLoginPhoneNumber.value = loginPhoneNumber.value.slice(0, 3) + '****' + loginPhoneNumber.value.slice(7, 11)
}

// 监听登录手机号
watch(() => loginPhoneNumber.value, (newVal) => {
    console.log('loginPhoneNumber', newVal);
    if (newVal.length === 11) {
        showLoginPhoneNumber.value = newVal.slice(0, 3) + '****' + newVal.slice(7, 11)
    } else {
        showLoginPhoneNumber.value = ''
    }
})

// 退出登录
function outLogined() {
    console.log('outLogined');
    phoneNumberStore.setLoginPhoneNumber("")
}

// 从url地址中获取phone参数
const queryParams = Utils.getQueryParam();
console.log(queryParams);
if (/^1[3-9]\d{9}$/.test(queryParams.phone)) {
    // phoneNumberStore.setLoginPhoneNumber(queryParams.phone);

    // 调用接口
    order.getOrderStatus({ mobile: queryParams.phone, productId: ProductId }).then(res => {
        console.log(res);
        if (res.status === 200) {
            if (res.data.res_code == "-1") {
                // 已订购, 登录成功
                phoneNumberStore.setPhoneNumber(queryParams.phone);
                return
            }
        }
    })
}
// phoneNumberStore.setLoginPhoneNumber("123213")

const loginWindowShow = ref(false)


// 切换tab
const showVideoTab = ref(true); // 控制视频列表显示隐藏
const showAiTab = ref(false); // 控制AI列表显示隐藏
function changeTab(tab) {
    if (tab === 'video') {
        showVideoTab.value = true;
        showAiTab.value = false;
    } else if (tab === 'ai') {
        showVideoTab.value = false;
        showAiTab.value = true;
    }
}


const aiTemplateList = ref([]); // AI模板列表
// 获取ai模板列表
function getAiTemplateList() {
    ai.getAiTemplateList({}).then(res => {
        console.log("getAiTemplateList-res", res);
        if (res.data.res_code === "0000") {
            console.log(res.data.ctrlList[0]);

            for (let i = 0; i < res.data.ctrlList.length; i++) {
                for (let j = 0; j < 10; j++) {
                    let musicListOne = res.data.ctrlList[i].musicList[j];
                    if (musicListOne == undefined) {
                        break;
                    }
                    console.log("musicListOne", musicListOne);
                    
                    let temp = { "imgUrl": musicListOne.artistPic, "title": musicListOne.name, "ringCode": musicListOne.background }
                    aiTemplateList.value.push(temp)
                }
            }


            // res.data.ctrlList[0]
            // aiTemplateList.value = res.data.ctrlList;
        } else {
            Snackbar['error'](res.data.res_message)
        }
        console.log("aiTemplateList", aiTemplateList.value);

    })
}

getAiTemplateList()



// 跳转到制作视频彩铃页面
function toMakeRing(background) {
    // 判断用户是否已登录, 
    if (loginPhoneNumber.value.length === 0) {
        loginWindowShow.value = true;
        return    
    }
    
    // 跳转到制作视频彩铃页面
    router.push({ name: 'makeRing', query: { background: background } });
}
</script>

<template>
    <var-sticky style="width: 100%;">
        <div class="userInfo">
            <!-- 手机号 -->
            <div class="phone" v-if="showLoginPhoneNumber">{{ showLoginPhoneNumber }}</div>
            <router-link to="/" class="rightTopLogin" v-if="!showLoginPhoneNumber">立即订购</router-link>
            <div v-if="loginPhoneNumber" class="rightTopLogin" @click="outLogined()">
                <svg t="1713001651183" class="icon i-icon" viewBox="0 0 1042 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4447" width="200" height="200">
                    <path
                        d="M581.632 697.344l126.976 0 0 194.56q0 33.792-10.24 58.88t-27.136 40.96-39.424 23.552-48.128 7.68l-452.608 0q-24.576 0-48.128-9.728t-41.472-27.136-29.184-40.96-11.264-52.224l0-706.56q0-24.576 11.776-47.104t30.208-39.936 40.96-28.16 45.056-10.752l449.536 0q26.624 0 50.176 11.776t41.472 29.696 28.16 40.448 10.24 44.032l0 188.416-126.976 0 1.024-195.584-452.608 0-1.024 713.728 452.608 0 0-195.584zM1021.952 505.856q37.888 30.72 2.048 60.416-20.48 15.36-44.544 37.888t-50.176 46.592-51.712 47.616-47.104 40.96q-23.552 18.432-40.448 18.432t-16.896-24.576q2.048-14.336 0.512-35.84t-1.536-36.864q0-17.408-12.288-21.504t-29.696-4.096l-40.96 0-62.464 0q-34.816 0-73.216-0.512t-73.216-0.512l-62.464 0-41.984 0q-8.192 0-17.92-1.536t-17.408-6.656-12.288-14.336-4.608-23.552q0-19.456-0.512-46.08t0.512-47.104q0-27.648 13.312-37.888t43.008-9.216l33.792 0 59.392 0q32.768 0 70.144 0.512t71.168 0.512l61.44 0 38.912 0q25.6 1.024 43.52-4.096t17.92-22.528q0-14.336 1.024-32.256t1.024-32.256q0-23.552 12.8-29.696t32.256 9.216q19.456 16.384 45.568 38.4t52.736 45.056 52.736 45.568 47.616 39.936z"
                        p-id="4448" fill="#ffffff"></path>
                </svg>
                退出
            </div>
            <div v-if="!loginPhoneNumber" class="rightTopLogin" @click="loginWindowShow = true">
                <svg t="1712910984447" class="icon i-icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="3516">
                    <path
                        d="M960 1024H64c0-115.776 135.424-212.48 320-244.096v-47.36c-76.16-44.416-128-126.016-128-220.544v-6.464c-37.12-13.312-64-47.872-64-89.536 0-41.664 26.88-76.224 64-89.536V320c0-141.376 114.624-256 256-256s256 114.624 256 256v6.464c37.12 13.312 64 47.872 64 89.536 0 41.664-26.88 76.224-64 89.536V512c0 94.528-51.84 176.128-128 220.48v47.36C824.64 811.52 960 908.224 960 1024z"
                        p-id="3517" fill="#ffffff"></path>
                </svg>
                登录
            </div>
        </div>
    </var-sticky>

    <div class="tab-select-box">
        <div class="tab-select-box-one" :class="{ tab_select_box_active: showAiTab }" @click="changeTab('ai')">AI特效模块
        </div>
        <div class="tab-select-box-one" :class="{ tab_select_box_active: showVideoTab }" @click="changeTab('video')">
            专区彩铃
        </div>
    </div>

    <!-- AI彩铃列表 -->
    <div v-if="showAiTab" class="videoList" v-infinite-scroll="load" infinite-scroll-distance="1"
        infinite-scroll-immediate="true" :infinite-scroll-disabled="isStopScroll" style="display: flex; flex-wrap: wrap; justify-content: space-evenly;">
        <div class="video-box" v-for="(item, index) in aiTemplateList" :key="index" style="width: 45%;">
            <VideoListOne :videoInfo="item" @click="toMakeRing(item.ringCode)" />
        </div>
        <p v-if="loading">Loading...</p>
        <p v-if="noMore">-----我也是有底线的-----</p>
    </div>

    <!-- 视频列表 -->
    <div v-if="showVideoTab" class="videoList" v-infinite-scroll="load" infinite-scroll-distance="1"
        infinite-scroll-immediate="true" :infinite-scroll-disabled="isStopScroll">
        <div class="video-box" v-for="(item, index) in showVideoList" :key="index">
            <SelectVideoListOne :videoInfo="item[0]" />
            <SelectVideoListOne :videoInfo="item[1]" />
        </div>
        <p v-if="loading">Loading...</p>
        <p v-if="noMore">-----我也是有底线的-----</p>
    </div>

    <!-- 登录窗口 -->
    <LoginWindow v-if="loginWindowShow" @close="loginWindowShow = false" />
</template>

<style scoped lang="less">
a :focus {
    outline: none;
    color: white;
    font-weight: 550;
}

a {
    color: white;
    font-weight: 550;
}

.userInfo {
    height: 14vw;
    width: 100%;
    background-color: #429D8C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4%;
    font-weight: 550;
    font-size: 5vw;
    color: white;

    .i-icon {
        width: 5vw;
        height: 5vw;
        margin-right: 5px;
    }

    .rightTopLogin {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1vw;
        border: 1px solid white;
        padding: 1vw 2vw;
    }
}

.videoList {
    width: 100%;

    background-color: #F2F4E7;

    .video-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

.tab-select-box {
    margin: 10px 0;
    display: flex;
    // background-color: red;
    width: 100%;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    font-size: 22px;
    font-weight: 700;

    .tab-select-box-one {
        height: 100%;
        width: 50%;
        border-radius: 50px;
        // text-align: center;
        line-height: 40px;
        color: #F6C576;
        border: #F6C576 solid 4px;
    }


    .tab_select_box_active {
        color: white;
        background-color: #F6C576;


    }
}
</style>