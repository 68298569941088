// 专题ID-内容运营中心提供
export const TopicId = "13563";
export const Node = "8175";
export const DEVICE_ID = "2000000002008"
export const DEVICE_SECRET = "VRfeT3hr3jDK"
export const CHANNEL_ID = "110"

// 订购成功返回页面
export const ReturnUrl = "135999999999999000206";
// 视频模板回调地址
export const TEST_CALLBACK_URL = "http://47.104.109.210/ringtones/interface/ring/ai/callback.jsp";
// DIY入库回调地址
export const TEST_DIY_CALLBACK_URL = "http://47.104.109.210/ringtones/interface/ring/ai/callback_diy.jsp";