<script setup>
import { defineEmits } from 'vue';

// 定义事件
const emit = defineEmits(['close']);

// 关闭弹窗
function emitClose() {
    emit('close');
}

</script>
<template>
    <!-- 创建一个遮罩 -->
    <div class="mask">
        <!-- 显示提示信息 -->
        <div class="tips-more">
            <div class="header">
                <svg @click="emitClose()" t="1712682760848" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="991" width="25" height="25">
                    <path
                        d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333zM373.290667 343.594667a21.333333 21.333333 0 0 0-27.221334 32.64l135.765334 135.68-135.765334 135.850666-2.474666 2.986667a21.333333 21.333333 0 0 0 32.64 27.178667L512 542.122667l135.765333 135.808 2.986667 2.474666a21.333333 21.333333 0 0 0 27.178667-32.64l-135.765334-135.808 135.765334-135.722666 2.474666-2.986667a21.333333 21.333333 0 0 0-32.64-27.178667L512 481.706667l-135.765333-135.68z"
                        p-id="992" fill="#d81e06"></path>
                </svg>
            </div>
            <div class="content">
                <!-- <div> -->
                <h4>一、业务说明</h4>
                <p>
                    <strong>1、资费说明：</strong>资费说明：“美拍视频彩铃精品包”为连续包月产品，资费10元/月，通过话费扣取，订购当月全额计扣，订购成功后，立即生效，用户当月多次订购该产品，只收取一次包月费用，不重复收费
                </p>

                <p>
                    <strong>2、续费说明：</strong>该产品按自然月计费，订购用户当月若不退订，则次月起月初系统将自动扣除10元/月的包月费用。
                </p>

                <p>
                    <strong>3、产品订购：</strong>
                    <ul>
                        <li>1）此业务仅支持中国电信Volte/4/5G用户，当前产品暂时仅面向部分省份放开订购，运营方将尽快推动未放开省份的订购。</li>
                        <li>2）若用户未具备视频彩铃功能，则系统将关联提示用户开通视频彩铃功能，视频彩铃功能费6元/月（实际费用以用户所属省收费标准为准）。</li>
                        <li>3）若用户已具备视频彩铃功能，则系统将直接开通美拍视频彩铃精品包产品。</li>
                    </ul>
                </p>

                <p>
                    <strong>4、产品权益：</strong>订购成功后，用户可在美拍视频彩铃精品包专区享受视频彩铃任换权益。系统每月推送一条视频彩铃内容放置用户个人铃音库，用户可登录爱音乐客户端、“热门视频彩铃”微信小程序中“我的”设置。
                </p>

                <p>
                    <strong>5、退订方式：</strong>请发送TDRJH到118100，退订当月费用不退还，退订美拍视频彩铃精品包时，不关联退订视频彩铃功能，如需退订视频彩铃功能，发送TDSP至118100，或拨打4008308100咨询退订。
                </p>

                <p>
                    6、用户若取消视频彩铃功能，则无法在专区内直接订购视频彩铃，且已设置的彩铃会同时失效；如需使用需重新开通视频彩铃功能。
                </p>

                <p>
                    7、用户访问页面及浏览视频彩铃时会产生流量费，计费按用户所属套餐正常收费。
                </p>

                <p>
                    8、如有疑问，请拨打客服热线4008308100。
                </p>
                <p>
                    9、产品提供方：北京瑞金汇联科技有限公司
                </p>
                <p>
                    10、服务提供方：天翼爱音乐文化科技有限公司
                </p>
                <h4>二、免责声明</h4>
                <p>
                    1、因不可抗力、技术故障等原因，天翼爱音乐文化科技有限公司有权根据相应情况调整或终止活动，而无需向用户进行赔偿或补偿。
                </p>
                <p>
                    2、因运营考虑，爱音乐公司可能会调整或者下架服务产品，但会提前通知用户，此种情形爱音乐公司无需担责；若用户不同意调整，可申请退订本产品。
                </p>
                <!-- </div> -->
            </div>

            <div class="footer" @click="emitClose()">
                <div class="btn">关闭</div>
            </div>
        </div>
    </div>

</template>
<style scoped lang="less">
.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    /* display: none; */
    display: flex;

    justify-content: center;
    align-items: center;
}

.tips-more {
    display: flex;

    --UI-BG: #fff;
    --UI-BG-1: #f7f7f7;
    --UI-BG-2: #fff;
    --UI-BG-3: #f7f7f7;
    --UI-BG-4: #4c4c4c;
    --UI-BG-5: #fff;
    --UI-FG: #000;
    --UI-FG-0: rgba(0, 0, 0, 0.9);
    --UI-FG-HALF: rgba(0, 0, 0, 0.9);
    --UI-FG-1: rgba(0, 0, 0, 0.5);
    --UI-FG-2: rgba(0, 0, 0, 0.3);
    --UI-FG-3: rgba(0, 0, 0, 0.1);
    --status-bar-height: 0px;
    --top-window-height: 0px;
    --window-left: 0px;
    --window-right: 0px;
    --window-margin: 0px;
    --window-top: calc(var(--top-window-height) + 0px);
    --window-bottom: 0px;
    user-select: none;
    color: var(--UI-FG-0);
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    width: 80%;
    background-color: #F9F1F7;
    border-radius: 8px;
    display: -webkit-flex;
    flex-flow: column;
    background-repeat: repeat;
    background-size: 100%;
}

.header {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: end;
    padding: 10px 10px 0 10px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    overflow: auto;
    width: 100%;
    height: 400px;
    padding: 0px 20px;
    color: #666;

    * {
        width: 100%;
    }

    p {
        font-size: 14px;
        line-height: 1.5;
        margin: 5px 0;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        font-size: 14px;
        line-height: 1.5;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    h4 {
        margin: 5px 0;
    }


}

.footer {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6C576;
    color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
</style>