
import { TopicId, Node, DEVICE_ID, DEVICE_SECRET, CHANNEL_ID } from '.././config/ai'

// 获取AI接口的headers信息和签名
export function getHeaders(params) {

    // 获取当前的年月日时分秒
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    // 补零操作
    month = month < 10? '0' + month : month;
    day = day < 10? '0' + day : day;
    hour = hour < 10? '0' + hour : hour;
    minute = minute < 10? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    
    // year转字符串
    // year = year.toString();
    // 格式化日期-字符串格式
    const formattedDate = year.toString() + month + day + hour + minute + second;
    // console.log(formattedDate);

    // 生成签名数据
    let data = DEVICE_ID + '&' + CHANNEL_ID + '&' + formattedDate;
    // 遍历参数对象，拼接成字符串
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            data += '&' + params[key];
        }
    }
    // data = '“10000000000000&1234&20160214162300&18910001234&135000000000000003147';
    // console.log(data);


    let authSignature = generateMacSignature(DEVICE_SECRET, data);
    // console.log(authSignature);
    
    let headers = {
        'auth-deviceid': DEVICE_ID,
        'auth-channelid': CHANNEL_ID,
        'devicePwd': DEVICE_SECRET,
        'auth-timestamp': formattedDate,
        'auth-signature-method': 'HmacSHA256',
        'auth-signature': authSignature,
        // 设置为form-data格式，否则会报错
        'Content-Type': 'multipart/form-data'
    }
    return headers;
}

const CryptoJS = require("crypto-js");
function generateMacSignature(secret, data) {
    // 使用 HmacSHA256 生成 HMAC 签名
    const hmac = CryptoJS.HmacSHA256(data, secret);
    // Base64 编码导出
    return CryptoJS.enc.Base64.stringify(hmac);
}

// // 示例用法
// const secret = 'your_secret_key';
// const data = 'your_data';
// const macSignature = generateMacSignature(secret, data);
// console.log(macSignature);