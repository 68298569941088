<script setup>

import { ref, computed } from 'vue';
import { showToast } from 'vant';
import { ai } from '@/api/api';
import { useRoute } from 'vue-router';
import { TEST_CALLBACK_URL, TEST_DIY_CALLBACK_URL } from '@/config/ai';
import { usePhoneNumberStore } from '/src/stores/phone.js'

// 登录手机号
const phoneNumberStore = usePhoneNumberStore()
// const loginPhoneNumber = computed(() => phoneNumberStore.loginPhoneNumber);
console.log('loginPhoneNumber', phoneNumberStore.loginPhoneNumber);


const route = useRoute();
const queryParam = route.query; // 这将给你一个包含所有查询参数的对象
// 防止重复点击
const isClickMakingRing = ref(true);

// const specificParam = route.query.yourParamName; // 替换 yourParamName 

// 上传图片
async function afterRead(file) {
    loading.value = true;
    // file.mm = 'ffffff';
    console.log('file', file)

    // 上传文件
    await ai.uploadFile({ "file": file.file }).then(res => {
        file.fileUrl = res.data.data.fileUrl;
        file.httpPrefix = res.data.data.httpPrefix;
        // file.fileUrl = 1111;
        // file.httpPrefix = 222222222;
        console.log('uploadFile', res)
        // fileList.value.push({ url: res.data.url });
        showToast('上传成功');
    }).catch(err => {
        console.log(err)
        showToast('上传失败');
    })
    loading.value = false;
    console.log('afterRead-fileList', fileList.value);
    return
}
// 上传的文件列表
const fileList = ref([
    // { url: 'https://fastly.jsdelivr.net/npm/@vant/assets/leaf.jpeg' },
    // Uploader 根据文件后缀来判断是否为图片文件
    // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
    // { url: 'https://cloud-image', isImage: true },
]);

// 视频模板合成文字
async function makeRing() {
    console.log('fileList', fileList.value)
    // 防止重复点击
    if (isClickMakingRing.value === false) {
        return;
    }

    // 如果图片小于3张, 则提示
    if (fileList.value.length < 3) {
        showToast('请上传至少3张图片');
        return;
    }

    loading.value = true;
    isClickMakingRing.value = false;

    // 遍历文件列表, 将多个图片拼接为字符串, 使用 | 分隔
    let imgStr = '';
    fileList.value.forEach(item => {
        imgStr += item.fileUrl + '|';
    });
    // 最后一个 | 去掉
    imgStr = imgStr.slice(0, -1);

    // 从url地址中获取background的值
    let background = queryParam.background;
    console.log('background', background);

    // 任务id
    let taskId = '';
    // 调用接口生成彩铃
    await ai.makeRing({ "background": background, "userPhotos": imgStr, "callbackUrl": TEST_CALLBACK_URL }).then(res => {
        console.log("合成请求发送成功-taskid: ", res.data.data.taskid);
        taskId = res.data.data.taskid;
        // showToast('生成成功');
    }).catch(err => {
        console.log(err)
        showToast('生成失败');
    })
    // 如果任务id为空, 则直接返回
    if (taskId == "") { return; }


    // 每秒调用接口查询结果, 直到生成成功或达到最大次数, 最大次数为10次
    let videoPath = await checkVideoGeneration(taskId);
    if (!videoPath) {
        loading.value = false;
        console.log("合成结果查询失败");
        console.log('videoPath', videoPath);
        // showToast('合成失败');
        return
    }
    console.log('videoPath', videoPath);

    // let videoPath = "/volte-video2.v1/ve_2679/f636e843ee0349aeb856bbc9062f7b27.mp4"
    let taskCode = ""
    console.log("loginPhoneNumber", phoneNumberStore.value);

    // DIY视频彩铃入库
    await ai.aiVideoSave({
        "videoName": "视频彩铃" + new Date().getTime(),
        "actorName": phoneNumberStore.loginPhoneNumber + "的彩铃" + new Date().getTime(),
        // "phone": "18110077050",
        "phone": phoneNumberStore.loginPhoneNumber,
        "filePath": videoPath,
        "type": 1,
        "callback": TEST_DIY_CALLBACK_URL,
        "packageId": "135999999999999000206",
    }).then(res => {
        console.log('DIY视频彩铃入库请求成功-getAiVideo', res)
        taskCode = res.data.data.taskCode;
        // showToast('设置成功');
    }).catch(err => {
        console.log(err)
        showToast('设置失败');
    })


    isClickMakingRing.value = true;

    let diyResult = await checkDiyVideoGeneration(taskCode); // 检查DIY视频入库结果
    console.log('diyResult', diyResult);
    showToast('设置成功');
    
    loading.value = false;
}

// 轮询视频合成结果任务状态, 直到生成成功或达到最大次数, 最大次数为20次
async function checkVideoGeneration(taskId) {
    return new Promise((resolve) => {
        let attempts = 0;
        const maxAttempts = 20; // 最大尝试次数
        const interval = setInterval(async () => {
            attempts++;
            const res = await ai.getMakeRingResult({ "taskid": taskId });
            console.log('getMakeRingResult', res);
            if (res.data.video_path) {
                clearInterval(interval);
                resolve(res.data.video_path); // 返回生成的视频路径
            }
            if (res.data.message) {
                clearInterval(interval);
                resolve(''); // 返回生成的视频路径
                showToast(res.data.message);
            }
            // 达到最大尝试次数后停止
            if (attempts >= maxAttempts) {
                clearInterval(interval);
                resolve(null); // 返回null表示失败
            }
        }, 3000); // 每秒检查一次
    });
}

// 轮询DIY入库结果任务状态, 直到生成成功或达到最大次数, 最大次数为20次
async function checkDiyVideoGeneration(taskCode) {
    return new Promise((resolve) => {
        let attempts = 0; // 尝试次数
        const maxAttempts = 20; // 最大尝试次数
        // 设置定时器
        const interval = setInterval(async () => {
            // 尝试次数+1
            attempts++;
            // 调用接口查询结果
            const res = await ai.getAiVideoSaveResult({ "taskCode": taskCode });
            // 打印日志
            console.log('getAiVideoSaveResult', res);
            // 如果判断
            if (res.data.remark) {
                clearInterval(interval);
                resolve(res.data.remark);
            }
            // 达到最大尝试次数后停止
            if (attempts >= maxAttempts) {
                clearInterval(interval);
                resolve('ok'); // 返回
            }
        }, 3000); // 每3秒检查一次
    });
}

// 加载中
const loading = ref(false);

</script>
<template>
    <div class="container">
        <div class="title">制作彩铃</div>

        <!-- 用户上传图片 -->
        <div class="upload-container">
            <div class="mini-title">上传图片</div>
            <div class="uploader-container">
                <van-uploader v-model="fileList" :after-read="afterRead" preview-size="40vw"
                    max-size="10240000" max-count="3"/>
            </div>
        </div>

        <!-- 用户输入文字 -->
        <van-field placeholder="请输入文字" v-model="text" />
        
        <div style="color: red; font-size: 18px;margin-top: 10px;">请上传3张图片，每次上传一张</div>

        <!-- 点击生成按钮 -->
        <div class="btn" @click="makeRing">合成视频</div>
    </div>

    <van-overlay :show="loading" @click="loading = false">
        <div class="wrapper" @click.stop>
            <div class="block">
                <van-loading type="spinner" vertical="true" size="80">正在全力运行中...</van-loading>
            </div>
        </div>
    </van-overlay>
</template>
<style scoped lang="less">
.container {
    background-color: bisque;
    width: 100vw;
    height: 100vh;


    .title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: white;
        background-color: #429D8C;
    }


    .upload-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .mini-title {
            width: 40%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            color: white;
            background-color: #F6C576;
            border-radius: 50px;
            margin: 20px;
        }

        .uploader-container {
            width: 94vw;
            padding: 10px;

            border: #429D8C 10px solid;
            border-radius: 5%;
        }

        :deep(.van-uploader__wrapper) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
        }

        :deep(.van-uploader__preview-delete--shadow) {
            transform: scale(2);
        }
    }


    .btn {
        width: 60%;
        height: 40px;
        line-height: 40px;
        margin: 50px 20%;
        color: white;
        background-color: #429D8C;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: 220px;
    height: 220px;
    background-color: #2e2626;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
}
</style>