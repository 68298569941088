import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useHomeVideoListStore = defineStore('homeVideoList', () => {
    const homeVideoList = ref([
        { "imgUrl": "/img/ring/嘴馋的猴子.jpg", "ringCode": "910100325579", "title": "嘴馋的猴子" },
        { "imgUrl": "/img/ring/忠实的大象.jpg", "ringCode": "910100325527", "title": "忠实的大象" },
        { "imgUrl": "/img/ring/忠厚的动物们.jpg", "ringCode": "910100325576", "title": "忠厚的动物们" },
        { "imgUrl": "/img/ring/正直的小动物们.jpg", "ringCode": "910100325578", "title": "正直的小动物们" },
        { "imgUrl": "/img/ring/展翅翱翔的鸟儿.jpg", "ringCode": "910100325528", "title": "展翅翱翔的鸟儿" },
        { "imgUrl": "/img/ring/粘人的小猴子.jpg", "ringCode": "910100325577", "title": "粘人的小猴子" },
    ]);

    return {
        homeVideoList,
    }
})