<script setup>
function back() {
    window.history.back();
}
</script>
<template>
    <div>
        <iframe scrolling="auto" src="https://m.imusic.cn/h5/privacy_policy_page" width="100%" height="95%" frameborder="0" />
        <var-button style="height: 5%; width: 100%;" type="info" @click="back">返回</var-button>
    </div>
</template>
<style scoped lang="less">
div {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: left;
    height: 100lvh;
    padding: 1%;

    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-overflow: auto;
}
</style>