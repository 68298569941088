import axios from 'axios';
import { showMessage } from "./status";   // 引入状态码文件
import { ElMessage } from 'element-plus'  // 引入el 提示框，这个项目里用什么组件库这里引什么


// 设置接口超时时间
axios.defaults.timeout = 60000;

// 请求地址，这里是动态赋值的的环境变量，下一篇会细讲，这里跳过
// @ts-ignore
// axios.defaults.baseURL = import.meta.env.VITE_API_DOMAIN;
// 生成环境
export const BASE_URL = {
    "business" :"https://hy.yhhdbj.cn",
    "business_ai" :"https://api.118100.cn/openapi/services",
    "backend_ai" :"https://hy.yhhdbj.cn",
};

// 开发环境
// export const BASE_URL = {
//     "business": "/api",
//     "business_ai": "/business_ai",
//     "backend_ai": "/backend_ai",
// };

//http request 拦截器
axios.interceptors.request.use(
    config => {
        // console.log("拦截器-config.headers",config.headers);
        
        // // 配置请求头
        // config.headers = {
        //     //'Content-Type':'application/x-www-form-urlencoded',   // 传参方式表单
        //     // 'Content-Type': 'application/json;charset=UTF-8',        // 传参方式json
        //     // 'token': '80cs483d59ca86ad0393cf8a98416e2a1'              // 这里自定义配置，这里传的是token
        // };
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

//http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            showMessage(response.status);           // 传入响应码，匹配响应码对应信息
            return Promise.reject(response.data);
        } else {
            ElMessage.warning('网络连接异常,请稍后再试!');
        }
    }
);

// 封装 GET POST 请求并导出
export function request(baseurl = '',url = '', params = {}, type = 'POST', headers = {}) {
    axios.defaults.baseURL = baseurl;
    //设置 url params type 的默认值
    return new Promise((resolve, reject) => {
        // 处理请求参数
        let config = {
            url,
            headers,
        };

        let promise
        if (type.toUpperCase() === 'GET') {
            config.method = 'GET';
            config.params = params;
        } else if (type.toUpperCase() === 'POST') {
            config.method = 'POST';
            config.data = params;
        }

        promise = axios(config);
        //处理返回
        promise.then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


// 封装 GET POST 请求并导出
export function requestPostFormData(baseurl = '',url = '', params = {}, type = 'POST', headers = {}) {
    axios.defaults.baseURL = baseurl;
    const formData = new FormData();
    for (let key in params) {
        formData.append(key, params[key]);
    }
    //设置 url params type 的默认值
    return new Promise((resolve, reject) => {
        // 处理请求参数
        let config = {
            url,
            headers,
        };

        let promise
        if (type.toUpperCase() === 'GET') {
            config.method = 'GET';
            config.params = formData;
        } else if (type.toUpperCase() === 'POST') {
            config.method = 'POST';
            config.data = formData;
        }

        promise = axios(config);
        //处理返回
        promise.then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}