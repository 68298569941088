<script setup>
import { ref, watch } from 'vue'
import TipsMore from '@/components/Home/TipsMore.vue'
import { usePhoneNumberStore } from '/src/stores/phone.js'
import { useHomeVideoListStore } from '/src/stores/homeVideoList.js'
import VideoListOne from '/src/components/Video/VideoListOne.vue'
import { order } from '/src/api/api.js'
import { ai } from '/src/api/api.js'
import { Snackbar } from '@varlet/ui'
import { ProductId } from '/src/config/order.js'
import { ChannelId } from '/src/config/order.js'
import { ReturnUrl } from '/src/config/order.js'
import qs from 'qs'



const isShowTipsMore = ref(false)
const useStore = usePhoneNumberStore() // 手机号码存储
const phoneNumber = ref(useStore.phoneNumber)
const isAgree = ref(useStore.isAgreement)
const videoLists = ref(useHomeVideoListStore().homeVideoList) // 首页视频列表数据

// 监听phoneNumber变化
watch(phoneNumber, (newVal) => {
  useStore.setPhoneNumber(newVal)
})
// 监听phoneNumber变化
watch(isAgree, (newVal) => {
  useStore.setIsAgreement(newVal)
})

async function submitOrder() {
  if (!isAgree.value) {
    Snackbar['error']('请勾选同意协议,才能订购内容')
    return
  }
  if (phoneNumber.value.length === 0) {
    Snackbar['error']('请输入手机号码')
    return
  }
  if (!/^1[3-9]\d{9}$/.test(phoneNumber.value)) {
    Snackbar['error']('请输入正确的手机号码')
    return
  }
  order.submitOrder({
    mobile: phoneNumber.value,
    productId: ProductId,
    channelId: ChannelId,
    returnUrl: ReturnUrl,
    cpparam: phoneNumber.value
  }).then(res => {
    // url解码并打印在控制台
    console.log('订单提交接口返回数据：' + JSON.stringify(res))
    if (res.data == '') {
      // 没有信息返回
      Snackbar['error']("订购失败, 请联系客服")
    } else if (res.data.res_code === "0") {
      console.log('订单提交成功：' + res.data.url);
      // 成功
      window.open(res.data.url, "_self")
    } else {
      // 失败
      Snackbar['error'](res.data.res_message)
    }
  }).catch(err => {
    console.log('订单提交接口请求失败：' + err)
    Snackbar['error']("系统繁忙，请稍后再试")
  })

}

// 切换tab
const showVideoTab = ref(true); // 控制视频列表显示隐藏
const showAiTab = ref(false); // 控制AI列表显示隐藏
function changeTab(tab) {
  if (tab === 'video') {
    showVideoTab.value = true;
    showAiTab.value = false;
  } else if (tab === 'ai') {
    showVideoTab.value = false;
    showAiTab.value = true;
  }
}


const aiTemplateList = ref([]); // AI模板列表
// 获取ai模板列表
async function getAiTemplateList() {
  await ai.getAiTemplateList({}).then(res => {
    console.log("getAiTemplateList-res", res);
    if (res.data.res_code === "0000") {
      console.log(res.data.ctrlList[0]);

      for (let i = 0; i < 10; i++) {
        let musicListOne = res.data.ctrlList[0].musicList[i];
        let temp = { "imgUrl": musicListOne.artistPic, "title": musicListOne.name, "ringCode": "" }
        aiTemplateList.value.push(temp)
      }
      // res.data.ctrlList[0]
      // aiTemplateList.value = res.data.ctrlList;
    } else {
      Snackbar['error'](res.data.res_message)
    }
    console.log("aiTemplateList", aiTemplateList.value);
  })
}


getAiTemplateList()

// 获取 CMS AI 模板链接地址



</script>
<template>
  <div class="rot">
    <img class="top-img" src="/img/web/top-2.jpg" alt="">
    <router-link class="select-video-btn" :to="{ name: 'selectVideo' }">
      <svg t="1712910984447" class="icon i-icon" viewBox="0 0 1024 1024" version="1.1"
        xmlns="http://www.w3.org/2000/svg" p-id="3516">
        <path
          d="M960 1024H64c0-115.776 135.424-212.48 320-244.096v-47.36c-76.16-44.416-128-126.016-128-220.544v-6.464c-37.12-13.312-64-47.872-64-89.536 0-41.664 26.88-76.224 64-89.536V320c0-141.376 114.624-256 256-256s256 114.624 256 256v6.464c37.12 13.312 64 47.872 64 89.536 0 41.664-26.88 76.224-64 89.536V512c0 94.528-51.84 176.128-128 220.48v47.36C824.64 811.52 960 908.224 960 1024z"
          p-id="3517" fill="#ffffff"></path>
      </svg>
      更换彩铃
    </router-link>
    <div class="center" style="background-image: url(/img/web/tips-2.jpg);">
      <!-- <el-form :model="form" label-width="auto" stylemax-width: 600px" id="homeview-phone-input"> -->
      <!-- <input class="phone-input" type="text" v-model="phoneNumber" placeholder="请输入手机号码"> -->
      <!-- <el-form-item> -->
      <input class="phone-input" placeholder="请输入手机号码" v-model="phoneNumber" maxlength="11" />
      <!-- </el-form-item> -->
      <!-- </el-form> -->
      <img class="order" src="/img/web/buy-2.png" alt="" @click="submitOrder()">
      <div class="tips">
        <p style="font-size: 14px; line-height: 1.8;"><input style="height: 18px; width: 18px; padding: 5px;"
            type="checkbox" id="isAgreeCheckbox" v-model="isAgree">我已仔细阅读并同意
          <!-- <a href="https://m.imusic.cn/imapp/3379">《互联网视频彩铃产品业务服务协议》</a>
          <a href="https://m.imusic.cn/imapp/3379">《爱音乐公司隐私政策》</a> -->
          <router-link :to="{ name: 'hlwspclcpywfwagreement' }">《互联网视频彩铃产品业务服务协议》</router-link>
          <router-link :to="{ name: 'ayygsysazcagreement' }">《爱音乐公司隐私政策》</router-link>
          <router-link :to="{ name: 'bjrjhlagreement' }">《北京瑞金汇联科技有限公司隐私协议》</router-link>

        </p>
        <p>1、产品名称：<span style="color: #f19f24;">美拍视频彩铃精品包</span></p>
        <p>2、产品资费：信息费10元/月，若用户未具备视频彩铃功能，则系统将关联提示用户开通视频彩铃功能，视频彩铃功能费6元/月（实际费用以用户所属省收费标准为准）。</p>
        <p>3、产品介绍：订购成功后可享受专区内视频彩铃内容任换权益。</p>
        <p>4、退订方式：<br />
          美拍视频彩铃精品包:拨打4008308100或发送TDRJH到118100根据提示操作退订;
          视频彩铃精品铃音包:拨打4008308100或发送TDMPJ到118100根据提示操作退订;
        </p>
        <p>5、客服热线:4008308100</p>
        <p>6、产品提供方:北京瑞金汇联科技有限公司</p>
        <p>7、服务提供方:天翼爱音乐文化科技有限公司</p>
        <p>服务省份:
          江苏未开通视频彩铃功能用户将开通视频彩铃精品铃音包，内含视频彩铃功能+美拍视频彩铃合作包权益,资费15元/月。</p>
        <!-- <p class="tips-more-btn" @click="isShowTipsMore = true">点击查看更多《业务说明》</p> -->
        <h4>一、业务说明</h4>
        <p>
          <strong>1、资费说明：</strong>资费说明：“美拍视频彩铃精品包”为连续包月产品，资费10元/月，通过话费扣取，订购当月全额计扣，订购成功后，立即生效，用户当月多次订购该产品，只收取一次包月费用，不重复收费
        </p>

        <p>
          <strong>2、续费说明：</strong>该产品按自然月计费，订购用户当月若不退订，则次月起月初系统将自动扣除10元/月的包月费用。
        </p>

        <p>
          <strong>3、产品订购：</strong>
        <ul>
          <li>1）此业务仅支持中国电信Volte/4/5G用户，当前产品暂时仅面向部分省份放开订购，运营方将尽快推动未放开省份的订购。</li>
          <li>2）若用户未具备视频彩铃功能，则系统将关联提示用户开通视频彩铃功能，视频彩铃功能费6元/月（实际费用以用户所属省收费标准为准）。</li>
          <li>3）若用户已具备视频彩铃功能，则系统将直接开通美拍视频彩铃精品包产品。</li>
        </ul>
        </p>

        <p>
          <strong>4、产品权益：</strong>订购成功后，用户可在美拍视频彩铃精品包专区享受视频彩铃任换权益。系统每月推送一条视频彩铃内容放置用户个人铃音库，用户可登录爱音乐客户端、“热门视频彩铃”微信小程序中“我的”设置。
        </p>

        <p>
          <strong>5、退订方式：</strong>请发送TDRJH到118100，退订当月费用不退还，退订美拍视频彩铃精品包时，不关联退订视频彩铃功能，如需退订视频彩铃功能，发送TDSP至118100，或拨打4008308100咨询退订。
        </p>

        <p>
          6、用户若取消视频彩铃功能，则无法在专区内直接订购视频彩铃，且已设置的彩铃会同时失效；如需使用需重新开通视频彩铃功能。
        </p>

        <p>
          7、用户访问页面及浏览视频彩铃时会产生流量费，计费按用户所属套餐正常收费。
        </p>

        <p>
          8、如有疑问，请拨打客服热线4008308100。
        </p>
        <p>
          9、产品提供方：北京瑞金汇联科技有限公司
        </p>
        <p>
          10、服务提供方：天翼爱音乐文化科技有限公司
        </p>
        <h4>二、免责声明</h4>
        <p>
          1、因不可抗力、技术故障等原因，天翼爱音乐文化科技有限公司有权根据相应情况调整或终止活动，而无需向用户进行赔偿或补偿。
        </p>
        <p>
          2、因运营考虑，爱音乐公司可能会调整或者下架服务产品，但会提前通知用户，此种情形爱音乐公司无需担责；若用户不同意调整，可申请退订本产品。
        </p>
      </div>
    </div>

    <div class="tab-select-box">
      <div class="tab-select-box-one" :class="{ tab_select_box_active: showAiTab }" @click="changeTab('ai')">AI特效模块
      </div>
      <div class="tab-select-box-one" :class="{ tab_select_box_active: showVideoTab }" @click="changeTab('video')">专区彩铃
      </div>
    </div>

    <!-- 视频彩铃tab -->
    <div class="video-list" v-show="showVideoTab">
      <img class="video-list-top-img" src="/img/web/video-list-top-2.png" alt="">
      <div class="video-list-box">
        <router-link class="video-list-item" :to="{ name: 'homeVideoInfo', params: { id: index } }"
          v-for="(item, index) in videoLists" :key="index">
          <VideoListOne :videoInfo="item" />
        </router-link>
      </div>
    </div>

    <!-- AI tab -->
    <div class="video-list" v-show="showAiTab">
      <img class="video-list-top-img" src="/img/web/video-list-top-2.png" alt="">
      <div class="video-list-box">
        <div class="video-list-item" style="width: 45%;" v-for="(item, index) in aiTemplateList" :key="index">
          <VideoListOne :videoInfo="item" @click="Snackbar['error']('请先订购并登陆后才能设置')" />
        </div>
      </div>
    </div>

    <TipsMore v-if="isShowTipsMore" @close="isShowTipsMore = false" />
  </div>
</template>

<style lang="less">
#homeview-phone-input {
  .el-input__wrapper {
    box-shadow: none !important;
    border-radius: 0;
    padding: 0 10px;
    border-radius: 3vw;
  }
}
</style>
<style scoped lang="less">
// 根元素
.rot {
  width: 100%;
  background-color: #F9F1F7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.top-img {
  width: 100%;
  height: 100%;
  margin-bottom: -8px;
}

.center {
  margin: 0;
  padding: 0;
  width: 100%;

  background-size: cover;
  /* 图像将被缩放以适合内容区域 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .order {
    // height: 14vw;
    width: 60vw;
    margin-top: 10px;
  }

  .phone-input {
    width: 80vw;
    height: 15vw;
    font-size: 16px;
    border: 4px solid #f19f24b9;
    border-radius: 4vw;
    background-color: #fff;
    color: black;
    padding: 0 4vw;

    .el-input__wrapper {
      box-shadow: none !important;
      border-radius: 3vw;
    }

    font-size: 22px;
    color: #666;
  }

  .phone-input:focus {
    outline: none;
    border: 4px solid #f19f24b9;

  }

  .tips {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 95%;
    font-size: 11px;
    color: #01455e;

    p {
      margin: 3px 0;
      text-align: left;
    }
  }
}

.video-list {
  width: 100%;
  border: 10px solid #F6C576;
  border-radius: 50px;
  padding: 1%;
  padding-top: 0;

  .video-list-top-img {
    margin-top: -2px;
    width: 46%;
  }

  .video-list-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;

    a {
      display: flex;
      width: 45%;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  }
}

.select-video-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00B799;
  padding: 2vw 4vw;
  border-radius: 2vw;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  font-weight: 700; // 加粗
  // 添加透明度
  opacity: 0.9;
  // 添加鼠标经过的样式

  .i-icon {
    width: 5vw;
    height: 5vw;
    margin-right: 2px;
  }
}

.tab-select-box {
  margin: 10px 0;
  display: flex;
  // background-color: red;
  width: 100%;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  font-size: 22px;
  font-weight: 700;

  .tab-select-box-one {
    height: 100%;
    width: 50%;
    border-radius: 50px;
    // text-align: center;
    line-height: 40px;
    color: #F6C576;
    border: #F6C576 solid 4px;
  }


  .tab_select_box_active {
    color: white;
    background-color: #F6C576;


  }
}

p {
  margin: 3px 0;
  text-align: left;
  align-items: start;


}

li {
  text-align: left;
}
</style>