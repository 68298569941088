import { createApp } from 'vue'
import App from './App.vue'
import './style.css'
import Varlet from '@varlet/ui'
import '@varlet/ui/es/style'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from '/src/router/router'
import { createPinia } from 'pinia'
import { Uploader } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import 'vant/lib/index.css';



const app = createApp(App)
app.use(ElementPlus)
app.use(createPinia())
app.use(Varlet)
app.use(router)
app.use(Uploader)
app.use(Loading)
app.use(Overlay)
app.mount('#app')
