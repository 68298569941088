<script setup>
import { defineProps } from 'vue'
defineProps({
    videoInfo: {
        'imgUrl': String,
        'ringCode': String,
        'title': String,
    }
})

const fit = 'cover'
</script>

<template>
    <div class="container">
        <el-image style="width: 97%; height: 56vw;border-radius: 20px;" :src="videoInfo.imgUrl" :fit="fit" />
        <img class="order" src="/img/web/set-2.png" alt="">
    </div>

</template>
<style scoped lang="less">
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
    background: #F0E7EA;
    border-radius: 25px;
    padding: 10px;
    margin: 3% 2%;

    .order {
        width: 80%;
        margin-top: 10px;
    }
}
</style>