
import HomeView from '@/views/HomeView/HomeView.vue'
import BjrjhlAgreementView from '@/views/AgreementView/BjrjhlAgreementView.vue'
import HlwspclcpywfwAgreementView from '@/views/AgreementView/HlwspclcpywfwAgreementView.vue'
import AyygsysazcAgreementView from '@/views/AgreementView/AyygsysazcAgreementView.vue'
import { createRouter, createWebHistory } from "vue-router";
import InfoView from '@/views/VideoView/InfoView.vue';
import SelectVideoView from '@/views/VideoView/SelectVideoView.vue';
import MakeRingView from '@/views/Ai/MakeRingView.vue';
const routes = [
    {
        path: '/',  // 对应的页面url地址
        name: 'home', // 路由名称, 方便在代码中使用
        component: HomeView // 展示的组件
    },
    {
        path: '/bjrjhlagreement',  // 动态路由使用:号
        name: 'bjrjhlagreement', // 路由名称, 方便在代码中使用
        component: BjrjhlAgreementView, // 展示的组件
        props: true // 允许路由中的参数在该组件中使用
    },
    {
        // 互联网视频彩铃产品业务服务协议
        path: '/hlwspclcpywfwagreement',  // 动态路由使用:号
        name: 'hlwspclcpywfwagreement', // 路由名称, 方便在代码中使用
        component: HlwspclcpywfwAgreementView, // 展示的组件
        props: true // 允许路由中的参数在该组件中使用
    },
    {
        // 爱音乐公司隐私政策
        path: '/ayygsysazcagreement',  // 动态路由使用:号
        name: 'ayygsysazcagreement', // 路由名称, 方便在代码中使用
        component: AyygsysazcAgreementView, // 展示的组件
        props: true // 允许路由中的参数在该组件中使用
    },
    {
        path: '/homeVideoInfo/:id',  // 动态路由使用:号
        name: 'homeVideoInfo', // 路由名称, 方便在代码中使用
        component: InfoView, // 展示的组件
        props: true // 允许路由中的参数在该组件中使用
    },
    {
        path: '/selectVideo',  // 动态路由使用:号
        name: 'selectVideo', // 路由名称, 方便在代码中使用
        component: SelectVideoView, // 展示的组件
        props: true // 允许路由中的参数在该组件中使用
    },
    {
        path: '/makeRing',
        name: 'makeRing',
        component: MakeRingView, // 展示的组件
        props: true // 允许路由中的参数在该组件中使用
    },
]

// const router = new VueRouter({
//     mode: 'history', // 路由模式, history仅支持IE10以上, hash模式路由中含有#号
//     base: process.env.BASE_URL,
//     routes
// })
const router = createRouter({
    history: createWebHistory(),
    routes,
    //使用浏览器的回退或者前进时，重新返回时保留页面滚动位置，跳转页面的话，不触发
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition;
        } else {
            return { top: 0 }
        }
    }
});
export default router