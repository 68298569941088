<script setup>
import { defineProps, ref, computed } from 'vue'
import { usePhoneNumberStore } from '/src/stores/phone.js'
import LoginWindow from '/src/components/Login/LoginWindow.vue'
import { Snackbar } from '@varlet/ui'
import { order } from '/src/api/api.js'
import { Dialog } from '@varlet/ui'
import router from '/src/router/router.js';

defineProps({
    videoInfo: {
        'imgUrl': String,
        'ringCode': String,
        'title': String,
    }
})

const fit = 'cover'

// 登录手机号
const phoneNumberStore = usePhoneNumberStore()
const loginPhoneNumber = computed(() => phoneNumberStore.loginPhoneNumber);

// 监听登录手机号
// watch(() => loginPhoneNumber, (newVal) => {
//     console.log('selectVideoListOne 监听 loginPhoneNumber', newVal);
// })


// 选择视频
function selectVideo(ringCode) {
    if (loginPhoneNumber.value) {
        // 发送请求

        console.log('选择视频', ringCode)
        changeVideo(ringCode)
    } else {
        // 打开登录窗口
        loginWindowShow.value = true
    }
}

// 是否展示登录窗口
const loginWindowShow = ref(false)


// 选择视频
async function changeVideo(ringCode) {
    if (loginPhoneNumber.value.length === 0) {
        Snackbar['error']("请输入手机号码")
        return
    }
    if (!/^1[3-9]\d{9}$/.test(loginPhoneNumber.value)) {
        Snackbar['error']("请输入正确的手机号码")
        return
    }

    // 调用接口
    order.changeVideoRing({ phoneNumber: loginPhoneNumber.value, toneCode: ringCode }).then(res => {
        console.log("设置视频" + JSON.stringify(res));

        if (res.status === 200) {
            if (res.data.res_code == "0") {
                // 已订购, 登录成功
                Snackbar['success']("设置成功")
                return
            } else {
                // 已订购, 登录成功
                // Snackbar['error']("设置失败")
                // return
                // 未订购, 弹窗提示
                // Snackbar['success']("您尚未订购该服务")
                notBuy('您尚未订购该服务, 请先订购后登录')
                return
            }
        } else {
            Snackbar['error']("系统繁忙，请稍后再试")
            return
        }

    }).catch(err => {
        console.log(err)
    })
}

/**
 * @description 未订购提示后跳转到首页
 * @param {*} message 提示信息
 */
async function notBuy(message) {
    notBuyActions[await Dialog({
        message: message,
        confirmButton: false,
        cancelButton: false,
    })]()
}
const notBuyActions = {
    // confirm: () => Snackbar.success('confirm'),
    // cancel: () => Snackbar.error('cancel'),
    close: () => router.push({ name: 'home' })
}
</script>

<template>
    <!-- 登录窗口 -->
    <LoginWindow v-if="loginWindowShow" @close="loginWindowShow = false" />
    <div class="container">
        <el-image style="width: 97%; height: 56vw;border-radius: 20px;" :src="videoInfo.imgUrl" :fit="fit" />
        <img class="order" src="/img/web/set-2.png" alt="" @click="selectVideo(videoInfo.ringCode)" />
    </div>
</template>
<style scoped lang="less">
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
    background: rgba(146, 239, 195, .6);
    border-radius: 25px;
    padding: 10px;
    margin: 3% 2%;

    .order {
        width: 80%;
        margin-top: 10px;
    }
}
</style>