<script setup>
function back() {
    window.history.back();
}
</script>
<template>
    <div class="container">
        <div class="row">
            <h2>北京瑞金汇联科技有限公司隐私协议</h2>

            <p>特别说明</p>
            <p>我们尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，应用平台会按照本隐私权政策的规定使用和披露您的个人信息。但我们将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，应用平台不会将这些信息对外披露或向第三方提供。我们会不时更新本隐私权政策。
            </p>
            <p>您在同意本产品及应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本产品及应用服务使用协议不可分割的一部分。</p>

            <h3>1. 适用范围</h3>
            <ul>
                <li><span>a)</span> 在您注册应用帐号时，您根据要求提供的个人注册信息；</li>
                <li><span>b)</span>
                    在您使用本产品及应用的网络服务，或访问平台网页时，平台自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
                </li>
                <li><span>c)</span> 通过合法途径从商业伙伴处取得的用户个人数据。</li>
            </ul>

            <p>您了解并同意，以下信息不适用本隐私权政策：</p>
            <ul>
                <li><span>a)</span> 您在使用平台提供的搜索服务时输入的关键字信息；</li>
                <li><span>b)</span> 收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；</li>
                <li><span>c)</span> 违反法律规定或违反本产品及应用规则行为及其已对您采取的措施。</li>
            </ul>

            <h3>2. 信息使用</h3>
            <ul>
                <li><span>a)</span>我们不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本产品及应用（含本产品及应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
                </li>
                <li><span>b)</span> 我们亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何平台用户如从事上述活动，一经发现，平台有权立即终止与该用户的服务协议。</li>
                <li><span>c)</span>
                    为服务用户的目的，平台及应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
                </li>
            </ul>

            <h3>3. 信息披露</h3>
            <p>在如下情况下，我们将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</p>
            <ul>
                <li><span>a)</span> 经您事先同意，向第三方披露；</li>
                <li><span>b)</span> 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</li>
                <li><span>c)</span> 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</li>
                <li><span>d)</span> 如您出现违反中国有关法律、法规或者本产品及应用的服务协议或相关规则的情况，需要向第三方披露；</li>
                <li><span>e)</span> 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</li>
                <li><span>f)</span>
                    在平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本平台有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。</li>
                <li><span>g)</span> 其它本平台根据法律、法规或者网站政策认为合适的披露。</li>
            </ul>

            <h3>4. 信息存储和交换</h3>
            <p>本产品收集的有关您的信息和资料将保存在本产品及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本产品收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>

            <h3>5. Cookie的使用</h3>
            <ul>
                <li><span>a)</span> 在您未拒绝接受cookies的情况下，本产品会在您的计算机上设定或取用cookies
                    ，以便您能登录或使用依赖于cookies的平台服务或功能。本产品及应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。</li>
                <li><span>b)</span>
                    您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的网络服务或功能。</li>
                <li><span>c)</span> 通过产品所设cookies所取得的有关信息，将适用本政策。</li>
            </ul>

            <h3>6. 信息安全</h3>
            <ul>
                <li><span>a)</span>
                    本产品帐号均有安全保护功能，请妥善保管您的用户名及密码信息。我们将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
                </li>
                <li><span>b)</span>
                    在使用本产品网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本产品的用户名及密码发生泄露，请您立即联络我们平台的客服，以便我们采取相应措施。
                </li>
            </ul>

            <h3>7. 版权声明</h3>
            <p>本业务内容来自电信，我们对非法转载、盗版等侵权行为的发生不具备充分的监控能力。</p>
            <p>当权利人发现在本平台的内容侵犯其知识产权权力时，可联系内容提供方，要求删除侵权内容或者断开侵权内容的链接。</p>
            <ul>
                <li><span>a)</span> 读取联系人：软件仅获取联系人信息，用于支持应用内的业务开通功能；</li>
                <li><span>b)</span> 读取短信内容：软件获取短信内容，用于登录验证，确保为用户本人登录，保障用户登录安全；</li>
                <li><span>c)</span> 获取精准位置：业务功能的使用依托于电信运营商，由于部分省份会存在某个时间段不能正常使用或设置失败的情况，需要获取用户位置信息为用户提供精确的反馈与服务。</li>
            </ul>
        </div>
        <var-button style="height: 5%; width: 100%;" type="info" @click="back">返回</var-button>
    </div>
</template>
<style scoped lang="less">
// div {
//     width: 98%;
//     margin: 1%;
//     color: black;
//     display: flex;
//     flex-direction: column;
//     align-items: left;
// }

.container {
    height: 100lvh;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1%;
    text-align: left;

    .row {
        height: 95%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        -webkit-overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0 5px;
        justify-content: start;
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        padding: 0 5px;
        // 去掉ul的默认样式
        list-style: none;
    }

    p {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start; 
        padding: 0 5px;
        
    }
}
</style>